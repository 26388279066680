<template>
  <div>
    <div v-if="branches.length > 0">
      <section class="w-100">
        <section>
          <h5 class="mb-0">Selecione a empresa que irá prestar o serviço</h5>
        </section>

        <div class="input-search w-100 p-0 my-2">
          <b-form-input
            v-model="search"
            placeholder="Digite para pesquisar"
            class="mb-2"
          />
          <b-button
            variant="light"
            class="px-2 d-flex align-items-center absolute icon-action"
          >
            <b-icon icon="search"></b-icon>
          </b-button>
        </div>
      </section>
      <cards-list grid="3" gap="10" class="content-security-modal my-4">
        <b-card
          no-body
          @click="handleSelect(branche)"
          class="pointer text-muted card-security p-3"
          :class="selectedBranche === branche && 'selected'"
          v-for="(branche, index) in branches"
          :key="index"
        >
          <span
            class="ball-selection"
            :class="selectedBranche === branche && 'selected'"
          />
          <strong class="text-primary d-block mb-2" v-if="branche.is_matrix">
            Matriz
          </strong>
          <strong class="text-muted d-block mb-2" v-else> Filial </strong>
          <div class="mr-md-4">
            <span class="d-block title">
              {{ branche.social_reason }}
            </span>
            <small class="text-muted">{{ branche.situation }}</small>
          </div>
          <div>
            <span class="d-block">
              <strong>CNPJ: </strong>
              {{ branche.cnpj | VMask("##.###.###/####-##") }}
            </span>
            <span class="d-block">
              <strong>E-mail: </strong>
              {{ branche.email }}
            </span>
          </div>
        </b-card>
      </cards-list>
      <div class="d-flex justify-content-end w-100  ">
        <b-button
          variant="primary"
          @click="sendSelecteds()"
          :disabled="!(selectedBranche && selectedBranche.email)"
        >
          Salvar seleção
        </b-button>
      </div>
    </div>
    <div v-else class="text-center">
      <img src=" " class="d-block mx-auto mb-3" height="100" />
      <p>Não há matrizes cadastradas para seleção</p>
      <router-link to="/my-enterprise/branche">
        <b-button>Adicionar seguros</b-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      empty: true,
      selectedBranche: {},
      items: ["1", "2"],
      branches: [],
    };
  },
  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user"]),
  },
  methods: {
    ...mapActions(["get_all_provider"]),
    sendSelecteds() {
      this.$emit("close", this.selectedBranche);
    },
    handleSelect(branche) {
      this.selectedBranche = branche;
    },
    getAllbranches() {
      this.get_all_provider({ matrix: this.user.enterprise.id }).then(
        ({ data }) => {
          this.empty = data.results.length === 0;
          this.branches = [
            { ...this.user.enterprise, is_matrix: true },
            ...data.results,
          ];
        }
      );
    },
  },
  mounted() {
    this.getAllbranches();
  },
};
</script>
<style lang="scss">
.content-security-modal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  .card-security {
    &.disabled {
      opacity: 0.4;
      filter: grayscale(5);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }
    &.selected {
      border-color: $primary;
      .title {
        color: $primary;
      }
    }
  }
}
</style>
